<template>
  <div class="Product">
    <div class="box1">
      <div class="title">Our main products</div>
      <div class="content">
        <div class="top">Bank Account Opening</div>
        <div class="center">
          <div class="text">
            <div class="Content">Content</div>
            <ul>
              <li>
                <span class="ulli"></span>1.Guidance for the documents
                preparation
              </li>
              <li><span class="ulli"></span>2.Communication with bank</li>
              <li>
                <span class="ulli"></span>3.Assist on application filling and
                submission
              </li>
              <li><span class="ulli"></span>4.Get the Bank account& Tokens</li>
            </ul>
          </div>
          <div class="step">
            <div class="Content">step</div>
            <ul>
              <li>1. Client provide documents</li>
              <li>2. Bank Review the company documents</li>
              <li>3. Bank apply for SAFE code</li>
              <li>4. Bank inspect the office address</li>
              <li>5. Make appointment with bank and submit the application</li>
              <li>6. Get bank account and tokens</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="content">
        <div class="top">Company Formation&Maintenance</div>
        <div class="center2">
          <div class="text">
            <div class="Content">Content</div>
            <ul>
              <li><span class="ulli"></span>1.Apply company license& stamps</li>
              <li><span class="ulli"></span>2.Secretary Service</li>
              <li><span class="ulli"></span>3.Annual Inspection</li>
              <li><span class="ulli"></span>4.Annual Audit</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="content">
        <div class="top">Bank Maintain service</div>
        <div class="center2">
          <div class="text">
            <div class="Content">Content</div>
            <ul>
              <li>
                <span class="ulli"></span>1.Assist to prepare the documents for
                payments settlement
              </li>
              <li>
                <span class="ulli"></span>2.Handle bank foreign currency
                exchange
              </li>
              <li><span class="ulli"></span>3.Handle bank reconciliation</li>
              <li><span class="ulli"></span>4.Update Company information</li>
              <li><span class="ulli"></span>5.Blacklist pre-check</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Product {
  width: 80%;
  margin: 0 auto;
}
.box1,
.box3 {
  width: 834px;
  margin: 0 auto;
  height: 280px;
  opacity: 1;
  border-radius: 16px;
  margin-bottom: 10%;
}
.box2 {
  margin-bottom: 50px;
}
.title {
  margin: 0 auto;
  width: 581px;
  height: 65px;
  /** 文本1 */
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 48px;
  color: rgba(15, 28, 73, 1);
}
.content {
  display: flex;
  flex-direction: column;
  background: rgba(248, 251, 252, 1);
}
.Content {
  line-height: 30px;
}
.center {
  display: flex;
  justify-content: space-around;
  text-align: left;
  padding: 20px;
}
.center2 {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 20px;
  padding-left: 50px;
}
.ulli {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  background: rgba(238, 238, 238, 1);
  margin-right: 15px;
}
li {
  line-height: 25px;
  color: rgba(135, 141, 164, 1);
}
.top {
  border-radius: 16px 16px 0 0;
  width: 834px;
  height: 56px;
  opacity: 1;
  background: rgba(9, 164, 216, 1);
  /** 文本1 */
  font-size: 18px;
  font-weight: 400;
  line-height: 56px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: middle;
  margin-bottom: 20px;
  padding-left: 40px;
  text-align: center;
}
.box2 {
  display: flex;
  justify-content: space-around;
  .top2 {
    border-radius: 16px 16px 0 0;
    width: 570;
    height: 56px;
    opacity: 1;
    background: rgba(9, 164, 216, 1);
    /** 文本1 */
    font-size: 18px;
    font-weight: 400;
    line-height: 56px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: middle;
    margin-bottom: 20px;
    padding-left: 40px;
  }
}
.left,
.right {
  width: 40%;
  text-align: left;
}
</style>
