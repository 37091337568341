<template>
  <div class="about">
    <div class="top">
      <div class="left">
        <div class="title">
          EZIPD is an International Fund-Transactions Solution Provider.
        </div>
        <p class="text">
          According to Clients' trade model, we select the feasible bank and help open the bank account which has been successfully opened for other clients and used for a long time. We won't stop the service once the bank account opened, we are more focus on solving practical problems that clients meet when use the bank account and we provide solutions. The practical problems have been summarized and accumulated so that new clients can easily solve them with our Service.
        </p>
      </div>
      <div class="right">
        <img :src="imgUrl" alt="" class="imgUrl" />
      </div>
    </div>
    <div class="middle">
      <div class="right">
        <img :src="imgUrl2" alt="" class="imgUrl" />
      </div>
      <div class="left">
        <div class="title">EZIPD’s Advantage&Service details</div>
        <p class="text">
          1.Focus on Bank Account Opening Service and Maintenance Service to
          provide good using experience of bank account. Especially help
          clients’ transactions settle smoothly.
        </p>
        <p class="text">2.Get the bank account in 1 month.</p>
        <p class="text">
          3.Bank Service helps receive payments into account, taking only 1-2
          days.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "AboutView",
  components: {},
  data() {
    return {
      imgUrl: require("@/assets/imgs/bank2.jpg"),
      imgUrl2: require("@/assets/imgs/bank4.jpg"),
    };
  },
};
</script>
<style scoped lang="less">
.about {
  margin: 0 auto;
  width: 80%;
}
.title {
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  text-align: left;
}
.top,
.middle {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  align-items: center;
}
.text {
  text-align: left;
  font-size: 16px;
  line-height: 28px;
}
.left {
  width: 533px;
  margin-left: 20px;
}
.imgUrl {
  width: 556px;
  height: 325px;
  border-radius: 8px;
}
</style>
