<template>
  <div class="bigbox">
    <div class="content">
      <div class="left">
        <div class="text">
          <img :src="icon" alt="" class="icon" />
          <span>EZIPD</span>
        </div>
        <div class="iocnfont">
          <i class="el-icon-message"><span>ember@ezipd.net</span></i>
        </div>
      </div>
      <div class="right">
        <span class="link">Quick link</span>
        <ul class="menu">
          <li
            @click="handleSelect('Home')"
            :class="activeIndex == 'Home' && 'active'"
          >
            Home
          </li>
          <li
            @click="handleSelect('About us')"
            :class="activeIndex == 'About us' && 'active'"
          >
            About us
          </li>
          <li
            @click="handleSelect('Case')"
            :class="activeIndex == 'Case' && 'active'"
          >
            Case
          </li>
          <li
            @click="handleSelect('Product')"
            :class="activeIndex == 'Product' && 'active'"
          >
            Product
          </li>
          <li
            @click="handleSelect('FAQ')"
            :class="activeIndex == 'FAQ' && 'active'"
          >
            FAQ
          </li>
        </ul>
      </div>
    </div>
    <div class="bottom">
      <span>© Copyright EZIPD 2023. All Right Reserved.</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeIndex: { type: String, default: "" },
  },
  data() {
    return {
      icon: require("../assets/imgs/icon.png"),
    };
  },
  methods: {
    handleSelect(key) {
      this.$emit("ok", key);
    },
  },
};
</script>

<style lang="less" scoped>
.bigbox {
  position: relative;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(8, 180, 228, 0.5),
    rgba(11, 126, 186, 0.5),
    rgba(97, 156, 237, 0.5)
  );
  display: flex;
  flex-direction: column;
}
.content {
  display: flex;
  height: 213px;
  text-align: left;
  width: 65%;
  align-items: center;
  margin: 0 auto;
}
.icon {
  width: 43px;
  height: 43px;
  vertical-align: middle;
  margin-right: 5px;
}
.bottom {
  color: #fff;
  width: 100%;
  text-align: center;
  line-height: 56px;
  background: rgba(16, 51, 101, 1);
}
.menu {
  background: rgba(0, 0, 0, 0);
  display: flex;
}
li {
  color: #ddd;
  width: 100px;
}
.active {
  color: #fff;
}
li:hover {
  cursor: pointer;
  color: #fff;
}
.iocnfont:hover {
  cursor: pointer;
  color: #fff;
}
.left {
  width: 50%;
  padding: 0 30px;
  color: #fff;
}
.right {
  flex: 1;
}
.link,
.text {
  font-size: 36px;
  font-weight: 400;
  color: #fff;
  line-height: 80px;
}
.iocnfont {
  font-size: 18px;
  color: #ddd;
  padding: 10px 0;
}
.iocnfont span {
  padding: 0 10px;
}
</style>
