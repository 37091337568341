<template>
  <div class="caseView">
    <div class="text">
      It's widely acknowledged that Chouchou Bank, a bank in mainland China,
      which would be confronted with a $300 million penalty. If not settle this
      fine could result in Chouchou Bank being placed on the SDN list. In the
      past, this bank has actively facilitated the opening of offshore accounts
      for a multitude of global corporations through referrals from clients and
      agents alike. The bank's managers also have been vigorously marketing this
      service, which led to a significant increase in offshore business
      operations. However, these operations have included dealings in regions
      sensitive to the US, prompting a complete cessation of financial
      transactions in these areas and subjecting the bank to substantial fines.
    </div>
    <div class="text_box">
      <div class="img"></div>
      <span class="text_right">
        Thus, it's imperative to caution regarding the bank that we recommended
        after reached to agreement: not to advertising it, as viable options are
        dwindling. A few banks in China, such as BOC and ABC, are capable of
        establishing offshore accounts, but this is contingent upon having
        robust business support overseas, a criterion not all customers can
        fulfill. Moreover, these procedures are notably time-consuming. The bank
        we have recommended is not one of China's major central banks but a
        commercial entity capable of engaging with funds in sensitive regions,
        offering a more user-friendly approach compared to central banks.
        Nonetheless, it could encounter the same predicament as Chouchou Bank —
        excessive visibility and usage could lead to a cessation of services,
        than it will force you to seek alternatives once more, and reliable
        banking partners are exceedingly rare. Many agents merely assist with
        account setup, which, post-establishment, may prove to be unusable or
        inconvenient.
      </span>
    </div>
    <div class="text">
      Hence, normally we only offer our recommendation to the clients with whom
      we have previously collaborated. As we acquainted with their business
      operations and are aware of the volume of business, enabling us to engage
      in proactive communication and clarification with the bank. In the future,
      when the international landscape improves and more options become
      available, we will be in a position to broaden our selection.
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.caseView {
  width: 78%;
  margin: 0 auto;
  text-align: left;
  min-height: 368px;
  border-radius: 12px;
  background: rgba(248, 251, 252, 1);
  box-shadow: 0px 20px 40px rgba(6, 45, 101, 0.08);
  margin-bottom: 40px;
  padding: 15px;
}

.text_box {
  display: flex;
  margin-top: 20px;
}
.text_right {
  margin-left: 17px;
  width: 50%;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 38px;
  color: rgba(0, 0, 0, 0.6);
}
.img {
  width: 50%;
  border-radius: 8px;
  background: url("@/assets/imgs/bank6.jpg") no-repeat;
  background-position-x: 50%;
  margin-bottom: 20px;
  background-size: cover;
}

.text {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 38px;
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  vertical-align: middle;
}
</style>
