<template>
  <div class="bigbox">
    <div class="content">
      <div class="banner-box">
        <div class="Layout">
          <img :src="icon" alt="" class="icon" />
          <ul class="menu">
            <li
              @click="handleSelect('Home')"
              :class="activeIndex == 'Home' && 'active'"
            >
              Home
            </li>
            <li
              @click="handleSelect('About us')"
              :class="activeIndex == 'About us' && 'active'"
            >
              About us
            </li>
            <li
              @click="handleSelect('Case')"
              :class="activeIndex == 'Case' && 'active'"
            >
              Case
            </li>
            <li
              @click="handleSelect('Product')"
              :class="activeIndex == 'Product' && 'active'"
            >
              Product
            </li>
            <li
              @click="handleSelect('FAQ')"
              :class="activeIndex == 'FAQ' && 'active'"
            >
              FAQ
            </li>
          </ul>
        </div>
        <div class="banner">
          <img :src="bannerImg" alt="" class="bannerImg" />
          <!-- <div class="centerText">{{ activeIndex }}</div> -->
        </div>
      </div>
      <HomeVue v-if="activeIndex == 'Home'" />
      <aboutUsVue v-if="activeIndex == 'About us'" />
      <caseViewVue v-if="activeIndex == 'Case'" />
      <productViewVue v-if="activeIndex == 'Product'" />
      <FAQVue v-if="activeIndex == 'FAQ'" />
      <FooterViewVue :activeIndex="activeIndex" @ok="handleSelect" />
    </div>
  </div>
</template>

<script>
import aboutUsVue from "@/components/Layout/aboutView.vue";
import caseViewVue from "@/components/Layout/caseView.vue";
import HomeVue from "@/components/Layout/Home.vue";
import productViewVue from "@/components/Layout/productView.vue";
import FooterViewVue from "@/components/FooterView.vue";
import FAQVue from "@/components/Layout/FAQ.vue";

export default {
  components: {
    productViewVue,
    caseViewVue,
    HomeVue,
    aboutUsVue,
    FooterViewVue,
    FAQVue,
  },
  data() {
    return {
      activeIndex: "Home",
      bannerImg: require("@/assets/imgs/banner.jpg"),
      icon: require("../assets/imgs/icon.png"),
    };
  },
  methods: {
    handleSelect(key) {
      this.activeIndex = key;
    },
  },
};
</script>

<style lang="less" scoped>
.bigbox {
  display: flex;
  width: 100vw;
}
.content {
  background: #fff;
  display: flex;
  flex-direction: column;
}
.el-menu.el-menu--horizontal {
  border: none;
  text-align: center;
}
.bannerImg {
  width: 88%;
  opacity: 0.6;
  border-radius: 16px;
}
.banner {
  position: relative;
  margin-bottom: 30px;
}
.Layout {
  width: 88%;
  display: flex;
  align-items: center;
  padding: 0 10%;
  margin: 0 auto;
  margin-bottom: 20px;
}
.menu {
  flex: 1;
  display: flex;
  align-items: center;
  height: 50px;
}
li {
  color: #ccc;
  width: 100px;
}
.active {
  color: #000;
}
li:hover {
  cursor: pointer;
  color: #000;
}
.banner-box {
  width: 88%;
  margin: 0 auto;
}
.centerText {
  line-height: 50px;
  color: #fff;
  font-size: 75px;
  font-weight: 700;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
}
.icon {
  width: 59px;
  margin-right: 10%;
}
</style>
