<template>
  <div class="Home">
    <div class="home">
      <div class="top">
        <div class="left">
          <div class="title">
            Our company mainly provides the Global Cash Flow Solutions
          </div>
          <p class="text">
            Most important for the Russian clients, Mid-Eastern clients, we
            could provide services on the right such as Russia/Dubai/China
            Cross-Border transaction solution. In Year 2023, we have buildup the
            cooperation with more than 50 Global agents, and opened more than
            200 cases for our clients and they has transferred the funds
            successfully/easily.
          </p>
        </div>
        <div class="right">
          <ul>
            <li>Global Off-Shore Bank Account Opening</li>
            <li>Cross Border Bank Account Opening</li>
            <li>Related Structure Operating Solution</li>
            <li>Bank Maintain Service</li>
          </ul>
        </div>
      </div>
      <div class="middle">
        <div class="middle_title">Why choose us</div>
        <div class="middle_img">
          <div v-for="item in List" :key="item.id" class="box">
            <img :src="item.imgUrl" alt="" class="imgUrl" />
            <p class="box_text">{{ item.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="foot">
        <div v-for="item in imgs" :key="item.id" class="trademark">
          <img :src="item.imgUrl" alt="" class="img" />
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      List: [
        {
          id: 1,
          imgUrl: require("@/assets/imgs/bank.jpg"),
          text: "We have solutions to open the bank accounts. We help customers select the best solutions.",
        },
        {
          id: 2,
          imgUrl: require("@/assets/imgs/bank3.jpg"),
          text: "We have bank service to assist transactions smoothly such as USD/RBs/UAE/EUR.",
        },
        {
          id: 3,
          imgUrl: require("@/assets/imgs/bank5.jpg"),
          text: "We have solutions for company fee/tax when operation",
        },
      ],
      imgs: [
        {
          id: 1,
          imgUrl: require("@/assets/imgs/CST.png"),
          text: "CTS",
        },
        {
          id: 2,
          imgUrl: require("@/assets/imgs/ES.webp"),
          text: "ES",
        },
        {
          id: 3,
          imgUrl: require("@/assets/imgs/pnd.webp"),
          text: "PND",
        },
        {
          id: 4,
          imgUrl: require("@/assets/imgs/XSS.png"),
          text: "XSS",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.Home {
  width: 100%;
}
.home {
  margin: 0 auto;
  width: 80%;
}
.top {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}
.text {
  text-align: left;
  font-size: 16px;
  line-height: 28px;
}
.left {
  width: 533px;
  margin-left: 20px;
}
.middle {
  margin-bottom: 30px;
}
.middle_title {
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  text-align: center;
  margin-bottom: 30px;
}
.middle_img {
  display: flex;
  justify-content: space-between;
}
.box {
  margin: 0 15px;
  width: 400px;
}
.box_text {
  text-align: center;
  font-size: 16px;
  line-height: 28px;
}
.title {
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  text-align: left;
}
.imgUrl {
  width: 360px;
  height: 273px;
  border-radius: 16px;
}
.right {
  width: 510px;
  height: 343px;
  opacity: 1;
  border-radius: 8px;
  margin-bottom: 20px;
  background: rgba(248, 251, 252, 1);
}
li {
  height: 56px;
  width: 430px;
  opacity: 1;
  border-radius: 4px;
  background: rgba(9, 164, 216, 1);
  margin: 20px auto;
  text-align: center;
  line-height: 56px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}
.footer {
  background: #f8fbfc;
  display: flex;
  align-items: center;
  justify-content: center;
}
.foot {
  width: 80%;
  display: flex;
  height: 300px;
  justify-content: space-around;
  margin: 60px 0;
  align-items: center;
}
.trademark {
  display: flex;
  flex-direction: column;
  align-items: center;
  .img {
    width: 200px;
    border-radius: 50%;
    height: 200px;
  }
  span {
    line-height: 50px;
    font-size: 24px;
    font-weight: 700;
  }
}
</style>
