<template>
  <div class="contentFAQ">
    <div class="title">
      Why should we open the bank account in China with Russia business?
    </div>
    <p>
      It is also because of the international political structure. Currently all
      countries use Swift system, but as the rise of China, the relationship
      between China and USA, the relationships among China and other countries,
      the banks of China is capable to not only wire through swift system, but
      also support currency settlements for the countries with which China has
      good relationship.
    </p>
    <div class="title">
      Can you get the account successfully? How long does it take to get the
      bank account?
    </div>
    <p>
      Of course, we have already been successfully opened more than 500 bank
      accounts for our clients. It takes about 1.5 to 2 months to get the
      account opened.
    </p>
    <div class="title">How to use the bank account?</div>
    <p>
      The bank will offer 2 tokens after the account opened, you could login the
      online banking with the token to handle the transactions, which will need
      2 tokens to complete it.
    </p>
    <div class="title">How to do the currencies exchange?</div>
    <p>
      It will need the company to seal the application form and send to the bank
      in advance, once confirm to do the exchange, the bank will help to fill
      the information and proceed the exchange.
    </p>
    <div class="title">Can pay Russian suppliers or UAE suppliers in USD?</div>
    <p>
      Currently most banks can only proceed RMB or RUB to Russian companies. For
      the payment to UAE companies, can be arranged in USD.
    </p>
    <div class="title">Why do we need EZIPD’s bank service?</div>
    <p>
      The Bank Service we provide is to maintain the bank account. If selecting
      Chinese banks as the carrier for fund settlement, the bank will require
      the company to provide related Business proof tn order to settle the
      payments into account, which means there are lots of communication with
      the bank in the future. As all knows, the transactions in Russia,
      Mid-Eastern countries are hard to go through, so to maintain the bank
      account well is key for lingering trade and currency flow. What’s more, it
      requires a dedicated personnel to handle annual inspection of the bank
      account and the maintenance of trade documents and certificates, which is
      equivalent to the fact that enterprises themselves also need someone to
      specifically coordinate with them. Due to lack of usage and practical
      experience, as well as language issues, EZIPD provides the Bank Service
      which obtained unanimous recognition from all clients. It is also an
      important chain for us to maintain long-term relationships with our
      clients. Therefore, to continuously understand the needs of our clients
      and solve their problems are an important core of our service.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.contentFAQ {
  width: 75%;
  height: 850px;
  border-radius: 16px;
  background: rgba(248, 251, 252, 1);
  padding: 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  overflow-y: scroll;
}
p {
  opacity: 1;
  /** 文本1 */
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 28px;
  color: rgba(135, 141, 164, 1);
  text-align: left;
  vertical-align: middle;
}
.title {
  height: 24px;
  opacity: 1;
  /** 文本1 */
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  text-align: left;
  color: rgba(15, 28, 73, 1);
  vertical-align: middle;
  margin: 15px 0;
}
</style>
